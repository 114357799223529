import axios from "axios"
import qs from "qs"
import { SECURE_SHARE_PREFIX } from "../Constants/Common"
import { clearAuth } from "../store/entities/auth"
import { spinnerHide, spinnerShow } from "../store/entities/globalSpinner"
import { notificationAdd } from "../store/entities/notification"
import { toasterAdd } from "../store/entities/toaster"
import { store } from "../store/persister"

export const HTTP_CODES = {
  OK: 200,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  REQUEST_FAILED: 422,
  INTERNAL_SERVER: 500,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
}

export const HTTP_VERBS = {
  DELETE: "delete",
  GET: "get",
  PATCH: "patch",
  POST: "post",
  PUT: "put",
}

const myAxios = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
})

// Add a request interceptor
myAxios.interceptors.request.use(
  (request) => {
    // console.log("request:", request)
    store.dispatch(spinnerShow())
    return request
  },
  (error) => {
    store.dispatch(spinnerHide())
    console.log("REQ-Error:", error)
    return Promise.reject(error)
  }
)

// Add a response interceptor
myAxios.interceptors.response.use(
  (response) => {
    //console.log('Response:', response)
    store.dispatch(spinnerHide())
    return response
  },
  (error) => {
    const alert = {
      type: "danger",
      title: error.message,
      description: ["URL: " + error.config.url],
    }
    if (error.response && error.response.data) {
      alert.description.push("Message: " + JSON.stringify(error.response.data))
    }
    // update redux, if authenticated
    if (store.getState().entities.auth.authenticated) {
      // if unauthorized clear auth data
      if (error.response.status === 401) {
        store.dispatch(clearAuth())
      }
      store.dispatch(notificationAdd(alert))
      store.dispatch(toasterAdd(alert))
    }
    
    store.dispatch(spinnerHide())

    return Promise.reject(error)
  }
)

// Default headers will be included on all requests
const getHeaders = () => {
  return {
    "X-Auth-Type-Browser-UI": "1",
  }
}

// Converts object to JSON string
const updateFilter = (qp) => {
  const keys = ["filter", "sortBy"]
  keys.forEach((k) => {
    if (qp[k]) {
      // qp[k] = u.toString(qp[k])
      qp[k] = JSON.stringify(qp[k])
    }
  })
  return qp
}

const newRequest = (method, url, queryParams = {}, data, headers = {}) => {
  // Do not include token in header, now it is managed my cookie
  // grab current state
  // const auth = store.getState().entities.auth
  // if (auth.authenticated) {
  //   const token = "Bearer " + (auth.authenticated ? auth.token : "")
  //   headers = { ...headers, Authorization: token }
  // }

  return myAxios.request({
    method: method,
    url: getFinalUrl(url),
    data: data,
    headers: { ...getHeaders(), ...headers },
    params: updateFilter(queryParams),
  })
}

const getFinalUrl = (url) => {
  if (url.startsWith(SECURE_SHARE_PREFIX)) {
    return url
  }
  return "/api" + url
}

export const api = {
  user: {
    requestOTP: (data) => newRequest(HTTP_VERBS.POST, "/lh-direct/request-otp", {}, data),
    login: (data) => newRequest(HTTP_VERBS.POST, "/lh-direct/login", {}, data),
    getUserDetails: (data) => newRequest(HTTP_VERBS.POST, "/lh-direct/user-details", {}, data),
    listReports: (data) => newRequest(HTTP_VERBS.POST, "/lh-direct/reports", {}, data),
    getDetailedReport: (data) => newRequest(HTTP_VERBS.POST, "/lh-direct/report", {}, data),
    getReportPDF: (data) => newRequest(HTTP_VERBS.GET, "/lh-direct/download-pdf-report", data, {}),
  },
  patient: {
    sendOTP: (data) => newRequest(HTTP_VERBS.POST, "/patient/send-otp", {}, data),
    verifyOTP: (data) => newRequest(HTTP_VERBS.POST, "/patient/verify-otp", {}, data),
    verifyContact: (queryData) => newRequest(HTTP_VERBS.GET, "/patient/verify-contact", queryData, {}),
    listReports: (queryData) => newRequest(HTTP_VERBS.GET, "/patient/list-reports", queryData, {}),
    getReport: (data) => newRequest(HTTP_VERBS.GET, "/patient/report", data, {}),
    logout: () => newRequest(HTTP_VERBS.GET, "/ndc_auth/sign_out"),
  },
  tests: {
    list: () => newRequest(HTTP_VERBS.GET, "/test/list", {}, {}),
  },
}
