import { Bullseye } from "@patternfly/react-core"
import React from "react"

export const CopyRight = () => {
  return (
    <div style={{ marginTop: "10px" }}>
      <Bullseye>
        <div>Copyright © 2023 Navani Diagnostic Centre. All rights reserved.</div>
      </Bullseye>
    </div>
  )
}
