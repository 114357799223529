import { Divider, Grid, GridItem, List, ListItem } from "@patternfly/react-core"
import React from "react"
import { FooterCard } from "../../Components/Footer/FooterCard"
import { CopyRight } from "../../Components/Footer/CopyRights"
import { Address, ContactNumbers, WorkingHours } from "../../Components/Footer/Contact"
import { Email } from "../../Components/Footer/Contact"

export const Footer = () => {
  return (
    <>
      <Divider />
      <Grid hasGutter>
        <GridItem md={6} sm={12}>
          <FooterCard
            title="About Us"
            body="Navani Diagnostic Centre (NDC) established in Namakkal Fort in the year 2003, NDC is a top
                player in the category Diagnostic Centres in the Namakkal. This well-known establishment acts
                as a one-stop destination servicing customers both local and from other parts of Namakkal.
                Over the course of its journey, this business has established a firm foothold in it’s
                industry."
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <FooterCard
            title="Contact Info"
            body={
              <>
                <ContactNumbers key="footer-contacts" numbers={["99655 65458", "99655 25458"]} />
                <Email email="navani.lab@gmail.com" />
                <Address address="24, Kottai Main Road, Namakkal, Tamil Nadu - 637001" />
                <WorkingHours
                  hours={{ "Monday to Saturday": "9:00am to 8:00pm", Sunday: "9:00am to 1:00pm" }}
                />
              </>
            }
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <FooterCard
            title="Quick Links"
            body={
              <List isPlain>
                <ListItem>
                  <a href="/#/privacyPolicy">Privacy Policy</a>
                </ListItem>
                <ListItem>
                  <a href="/">Terms and Conditions</a>
                </ListItem>
                <ListItem>
                  <a href="/">FAQs</a>
                </ListItem>
                <ListItem>
                  <a href="/">Contacts Us</a>
                </ListItem>
              </List>
            }
          />
        </GridItem>
      </Grid>
      <Divider />
      <CopyRight />
    </>
  )
}
