import { combineReducers } from "redux"
import authReducer from "./entities/auth"
import dashboardReducer from "./entities/dashboard"
import spinnerReducer from "./entities/globalSpinner"
import notificationReducer from "./entities/notification"
import toasterReducer from "./entities/toaster"
import patientReportReducer from "./entities/patient/report"

export default combineReducers({
  notification: notificationReducer,
  toaster: toasterReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  spinner: spinnerReducer,
  patientReport: patientReportReducer
})
