import React from "react"
import "./KeyValue.scss"
import { LastSeen } from "./Time"

export const getKeyValue = (key = "", value = "", index = "") => {
  return (
    <div className="key-value-map" key={key + index}>
      <span className="key">{key}</span> <span className="value">{value}</span>
    </div>
  )
}

export const KeyValue = ({ name, value, index = 0 }) => {
  let finalValue = ""
  switch (name) {
    case "modifiedOn":
    case "timestamp":
    case "lastEvaluation":
    case "lastSuccess":
    case "lastRun":
    case "since":
      finalValue = <LastSeen date={value} tooltipPosition="top" />
      break

    default:
      finalValue = value !== undefined ? String(value) : ""
  }
  return getKeyValue(name, finalValue, index)
}

export const KeyValueMap = ({ data = {} }) => {
  if (data === undefined || data === null) {
    return <span>No Data</span>
  }
  const names = Object.keys(data)
  if (names.length === 0) {
    return <span>No Data</span>
  }

  names.sort()
  const elements = names.map((name, index) => {
    const value = typeof data[name] !== "object" ? data[name] : JSON.stringify(data[name], null, " ")
    return <KeyValue key={index} name={name} value={value} index={index} />
  })
  return elements
}
