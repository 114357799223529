import React from "react"
import { api } from "../../../Service/Api"
import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table"
import { Button, Label } from "@patternfly/react-core"
import { redirect as r, routeMap as rMap } from "../../../Service/Routes"
import { connect } from "react-redux"
import TableLoading from "../../../Components/Loading/TableLoading"

const columnNames = {
  name: "Name",
  gender: "Gender",
  patientType: "Type",
}

class ListPatient extends React.Component {
  state = {
    patients: [],
    loading: true,
  }

  componentDidMount() {
    const { userData, isAuthenticated, history } = this.props
    // if not logged in redirect to login page
    if (!isAuthenticated) {
      r(history, rMap.patient.login)
      return
    }

    api.patient
      .verifyContact({ contact: userData.user.contactNumber })
      .then((res) => {
        const patients = res.data.result.map((patient) => {
          return {
            id: patient.id,
            patientType: patient.patientType,
            contact: patient.contact,
            name: patient.fullName,
            gender: patient.sex,
            labUserId: patient.labUserId,
          }
        })

        this.setState({ patients: patients, loading: false })
      })
      .catch((_err) => {
        if (_err.response.status === 401) {
          r(history, rMap.patient.login)
          return
        } else {
          this.setState({ patients: [], loading: false })
        }
      })
  }

  render() {
    const { loading, patients } = this.state
    if (loading) {
      return <TableLoading key="profile-loading" />
    }

    patients.sort((a, b) => a.id - b.id)

    return (
      <>
        <Table aria-label="main-table" isStriped>
          <Thead>
            <Tr>
              <Th>{columnNames.name}</Th>
              <Th>{columnNames.gender}</Th>
              <Th>{columnNames.patientType}</Th>
            </Tr>
          </Thead>
          <Tbody key="patient-main-table">
            {patients.map((patient) => {
              return (
                <Tr key={patient.id}>
                  <Td dataLabel={columnNames.name}>
                    <Button
                      variant="link"
                      onClick={() =>
                        r(this.props.history, rMap.patient.report.reportList, {
                          labUserId: patient.labUserId,
                        })
                      }
                    >
                      {patient.name}
                    </Button>
                  </Td>
                  <Td dataLabel={columnNames.gender}>{patient.gender}</Td>
                  <Td dataLabel={columnNames.patientType}>
                    <PatientType contact={patient.contact} />
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </>
    )
  }
}

// helper functions
const PatientType = ({ contact = "" }) => {
  let actualType = "Dependent"
  let displayColor = "blue"
  if (contact !== "") {
    actualType = "Primary"
    displayColor = "green"
  }
  return (
    <Label variant="outline" color={displayColor}>
      {actualType}
    </Label>
  )
}

const mapStateToProps = (state) => ({
  userData: state.entities.auth,
  isAuthenticated: state.entities.auth.authenticated,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ListPatient)
