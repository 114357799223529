import { Button, Label } from "@patternfly/react-core"
import { MailBulkIcon, PhoneAltIcon } from "@patternfly/react-icons"
import React from "react"
import "./Contact.scss"
import { KeyValueMap } from "../DataDisplay/KeyValue"

export const ContactNumber = ({ number = "" }) => {
  return (
    <Button variant="link" className="contact-number" icon={<PhoneAltIcon />}>
      <a href={`tel:${number}`}>{number}</a>
    </Button>
  )
}

export const ContactNumbers = ({ numbers = [] }) => {
  const _numbers = numbers.map((number) => <ContactNumber key={number} number={number} />)
  return <div className="contact-numbers">{_numbers}</div>
}

export const Email = ({ email = "", hideIcon = false }) => {
  return (
    <Button variant="link" className="contact-email" icon={hideIcon ? null : <MailBulkIcon />}>
      <a href={`mailto:${email}`}> {email}</a>
    </Button>
  )
}

export const WorkingHours = ({ hours = {} }) => {
  const workingHours = Object.keys(hours).map((key, index) => {
    return
  })
  return (
    <>
      <div style={{ marginTop: "7px" }}>
        <u>Working Hours</u>
      </div>
      <KeyValueMap data={hours} />
    </>
  )
}

export const Address = ({ address = "" }) => {
  return <div style={{ marginTop: "7px" }}>{address}</div>
}
