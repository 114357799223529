import ReportListPage from "../Pages/Patient/Report/ListReportPage"
import PatientListPage from "../Pages/Patient/Report/ListProfilePage"

import Login from "../Pages/Login/Login"
import Logout from "../Pages/Login/Logout"
import HomePage from "../Pages/Home/HomePage"
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy"

const routeMap = {
  home: "/",
  contactUs: "/contact-us",
  privacyPolicy: "/privacyPolicy",
  patient: {
    report: {
      reportList: "/report/detail/:labUserId",
      patientList: "/report/profile/list",
    },
    test: {
      list: "/tests",
    },
    offer: {
      list: "/offers",
    },
    login: "/login",
    logout: "/logout",
  },
}

const routes = [
  // {
  //   id: "tests",
  //   title: "Tests",
  //   to: routeMap.patient.test.list,
  //   routeMatch: "/tests",
  //   component: MockFilterSidePanelExample,
  // },
  // {
  //   id: "offers",
  //   title: "Offers",
  //   to: routeMap.patient.offer.list,
  //   routeMatch: "/offer",
  //   component: TbdPage,
  // },
  {
    id: "patient-report-list",
    title: "My Reports",
    to: routeMap.patient.report.patientList,
    routeMatch: "/report",
    component: PatientListPage,
  },
  //{
  //  id: "contact-us",
  //  title: "Contact US",
  //  to: routeMap.contactUs,
  //  routeMatch: "/contact-us",
  //  component: TbdPage,
  //},
]

const hiddenRoutes = [
  {
    to: routeMap.home,
    component: HomePage,
  },
  {
    to: routeMap.patient.report.reportList,
    component: ReportListPage,
  },
  {
    to: routeMap.patient.login,
    component: Login,
  },
  {
    to: routeMap.patient.logout,
    component: Logout,
  },
  {
    to: routeMap.privacyPolicy,
    component: PrivacyPolicy,
  },
]

const redirect = (history, to = routeMap.home, urlParams = {}) => {
  if (history === null) {
    return
  }
  //console.log(history, to, urlParams)
  //const to = t(routeMap, name).safeString
  if (to) {
    let finalPath = to
    Object.keys(urlParams).forEach((key) => {
      finalPath = finalPath.replace(":" + key, urlParams[key])
    })
    history.push(finalPath)
  }
}

export { routes, hiddenRoutes, routeMap, redirect }
