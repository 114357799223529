import { Grid, GridItem, Skeleton, Stack, StackItem } from "@patternfly/react-core"
import React from "react"

const CustomCell = () => {
  return (
    <StackItem>
      <div style={{ height: "55px" }}>
        <Skeleton height="100%" />
      </div>
    </StackItem>
  )
}

const TableLoading = ({ numberOfRows = 6 }) => {
  const items = []
  for (let index = 0; index < numberOfRows; index++) {
    items.push(<CustomCell key={index} />)
  }
  return <Stack key="loading" hasGutter>{items}</Stack>
}

export default TableLoading
