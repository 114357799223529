import "./App.scss"
import React from "react"
import {
  Masthead,
  MastheadMain,
  MastheadBrand,
  MastheadContent,
  Brand,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Page,
  PageSection,
  PageSectionVariants,
  Nav,
  NavList,
  NavItem,
  ToolbarGroup,
  Badge,
} from "@patternfly/react-core"
import logo from "./logo/ndc_logo_white.svg"
import logoBase from "./logo/ndc_logo_white.svg"
import { Footer } from "./Pages/Footer/Footer"
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary"
import { hiddenRoutes, routes } from "./Service/Routes"
import { Redirect, Route, Switch } from "react-router-dom"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Toaster from "./Components/Toaster/Toaster"
import { HeaderSpinner } from "./Components/Spinner/Spinner"

class App extends React.Component {
  state = {
    activeGroup: "grp-1",
    activeItem: "grp-1_itm-1",
  }

  componentDidMount() {}

  renderContent = () => {
    const allRoutes = []
    routes.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((sItem) => {
          if (!sItem.isSeparator) {
            allRoutes.push(<Route key={sItem.to} exact path={sItem.to} component={sItem.component} />)
          }
        })
      } else if (!item.isSeparator) {
        allRoutes.push(<Route key={item.to} exact path={item.to} component={item.component} />)
      }
    })
    hiddenRoutes.forEach((item) => {
      allRoutes.push(<Route key={item.to} exact path={item.to} component={item.component} />)
    })

    return (
      <Switch>
        {allRoutes}
        <Redirect from="*" to="/" key="default-route" />
      </Switch>
    )
  }

  onMenuSelect = (data) => {
    const { history } = this.props
    history.push(data.target.pathname)
  }

  render() {
    const { location } = this.props

    // selected menu
    let menuSelection = "/"
    if (location.pathname.startsWith("/login") || location.pathname.startsWith("/logout")) {
      menuSelection = "login-logout"
    } else {
      for (let rIndex = 0; rIndex < routes.length; rIndex++) {
        const r = routes[rIndex]
        if (location.pathname.startsWith(r.routeMatch)) {
          menuSelection = r.id
          break
        }
      }
    }

    // add login or logout
    const loginLogoutComponent = this.props.isAuthenticated ? (
      <NavItem
        key="login-logout"
        itemId="login-logout"
        to={"/logout"}
        isActive={menuSelection === "login-logout"}
        preventDefault={true}
      >
        Logout
      </NavItem>
    ) : (
      <NavItem
        key="login-logout"
        itemId="login-logout"
        to={"/login"}
        isActive={menuSelection === "login-logout"}
        preventDefault={true}
      >
        Login
      </NavItem>
    )

    const navItems = (
      <Nav aria-label="Horizontal subnav local" variant="horizontal" onSelect={this.onMenuSelect}>
        <NavList>
          {routes.map((m) => {
            return (
              <NavItem
                key={m.id}
                itemId={m.id}
                to={m.to}
                isActive={menuSelection === m.id}
                preventDefault={true}
              >
                {m.title}
                {m.isExperimental ? (
                  <span className="mc-badge-experimental">
                    <Badge>{"Experimental"}</Badge>
                  </span>
                ) : null}
              </NavItem>
            )
          })}
          {loginLogoutComponent}
        </NavList>
      </Nav>
    )

    const headerToolbar = (
      <Toolbar id="toolbar" isFullHeight isStatic>
        <ToolbarContent>
          <ToolbarGroup
            variant="icon-button-group"
            align={{
              default: "alignRight",
            }}
            spacer={{
              default: "spacerNone",
              md: "spacerMd",
            }}
          >
            <ToolbarItem>{this.props.showGlobalSpinner ? <HeaderSpinner size="lg" /> : null} </ToolbarItem>
          </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>
    )

    const header = (
      <>
        <Masthead>
          <MastheadMain>
            <MastheadBrand href="/">
              <Brand
                className="nl-header-logo"
                src={logoBase}
                alt="navani lab default logo"
                widths={{ default: "70px" }}
              />
            </MastheadBrand>
            {navItems}
          </MastheadMain>
          <MastheadContent>{headerToolbar}</MastheadContent>
        </Masthead>
      </>
    )

    return (
      <>
        <Toaster />
        <Page
          header={header}
          onPageResize={() => {
            window.dispatchEvent(new Event("resize"))
          }}
        >
          <PageSection
            style={
              {
                /*minHeight: "1500px" */
              }
            }
            variant={PageSectionVariants.light}
            isFilled
          >
            <ErrorBoundary hasMargin>{this.renderContent()}</ErrorBoundary>
          </PageSection>
          <PageSection variant={PageSectionVariants.light} isFilled={false}>
            <Footer />
          </PageSection>
        </Page>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.entities.auth.authenticated,
  showGlobalSpinner: state.entities.spinner.show,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
