import { Card, CardBody, CardTitle } from "@patternfly/react-core"
import React from "react"
import "./FooterCard.scss"

export const FooterCard = ({ title = "", body = "" }) => {
  return (
    <Card isPlain={true} isFullHeight className="ndc-footer-card">
      <CardTitle>{title.toUpperCase()}</CardTitle>
      <CardBody isFilled>{body}</CardBody>
    </Card>
  )
}
