import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import "@patternfly/react-core/dist/styles/base.css"
import { HashRouter } from "react-router-dom"

import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { persistor, store } from "./store/persister"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import Moment from "react-moment"
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary"

const root = ReactDOM.createRoot(document.getElementById("root"))

// https://github.com/headzoo/react-moment#pooled-timer
Moment.startPooledTimer(30000)

root.render(
  // <React.StrictMode>
  <HashRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </HashRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
