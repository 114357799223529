import React from "react"
import {
  Button,
  DescriptionList,
  DescriptionListGroup,
  DescriptionListTerm,
  DescriptionListDescription,
  Divider,
} from "@patternfly/react-core"
import { clearAuth } from "../../store/entities/auth"
import { connect } from "react-redux"
import { redirect as r, routeMap as rMap } from "../../Service/Routes"
import { api } from "../../Service/Api"

class Logout extends React.Component {
  state = {}

  doLogout = () => {
    const { clearAuth } = this.props
    clearAuth()
    api.patient.logout().then((_res) => {
      r(this.props.history, rMap.patient.login)
    })
  }

  render() {
    const { userData } = this.props

    return (
      <>
        <DescriptionList>
          <DisplayKeyValue dataKey="ID" dataValue={userData.user.id} />
          <DisplayKeyValue dataKey="Name" dataValue={userData.user.fullName} />
          <DisplayKeyValue dataKey="Mobile Number" dataValue={userData.user.contactNumber} />
        </DescriptionList>
        <Divider style={{ marginTop: "20px" }} />
        <div style={{ marginTop: "7px" }}>
          <Button variant="danger" onClick={this.doLogout}>
            Logout
          </Button>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userData: state.entities.auth,
})

const mapDispatchToProps = (dispatch) => ({
  clearAuth: () => dispatch(clearAuth()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)

// helper functions
const DisplayKeyValue = ({ dataKey, dataValue }) => {
  return (
    <DescriptionListGroup>
      <DescriptionListTerm>{dataKey}</DescriptionListTerm>
      <DescriptionListDescription>{dataValue}</DescriptionListDescription>
    </DescriptionListGroup>
  )
}
