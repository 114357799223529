import React from "react"
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupItem,
  Text,
  FormHelperText,
  HelperText,
  HelperTextItem,
} from "@patternfly/react-core"
import { api } from "../../Service/Api"
import { connect } from "react-redux"
import { toasterAdd } from "../../store/entities/toaster"
import { authSuccess } from "../../store/entities/auth"
import { redirect as r, routeMap as rMap } from "../../Service/Routes"
import { validate, validateAll } from "../../Util/Validator"

const stageVerifyContact = "verifyContact"
const stageVerifyOTP = "verifyOTP"

class Login extends React.Component {
  state = {
    data: { contactNumber: "", verifyKey: "" },
    contactNumberValidated: "default",
    verifyKeyValidated: "default",
    stage: stageVerifyContact,
    submitEnabled: false,
    inProgress: false,
    errorMsg: "",
  }

  sendOTP = (data) => {
    api.patient
      .sendOTP({ countryCode: "91", username: data.contactNumber })
      .then((_res) => {
        this.setState({ stage: stageVerifyOTP, submitEnabled: false, inProgress: false }, () => {
          this.props.addAlert({
            title: "OTP dispatched",
            description: ["An OTP has been dispatched; please verify it using your mobile device"],
            type: "success",
          })
        })
      })
      .catch((_err) => {
        this.setState({ submitEnabled: true, inProgress: false }, () => {
          this.props.addAlert({
            title: "Invalid request",
            description: ["Invalid mobile number or the number not registered with us"],
            type: "danger",
          })
        })
      })
  }

  verifyOTP = (data) => {
    api.patient
      .verifyOTP({ username: data.contactNumber, verifyKey: data.verifyKey })
      .then((res) => {
        const data = res.data
        // update redux
        this.props.authSuccess(data)
        this.setState({ stage: stageVerifyOTP, submitEnabled: true, inProgress: false }, () => {
          r(this.props.history, rMap.patient.logout)
        })
      })
      .catch((err) => {
        this.setState({ submitEnabled: true, inProgress: false }, () => {
          this.props.addAlert({
            title: "Incorrect OTP",
            description: ["OTP is incorrect"],
            type: "danger",
          })
        })
      })
  }

  executeSubmit = (stage = "", data = {}) => {
    switch (stage) {
      case stageVerifyContact:
        this.setState({ submitEnabled: false, inProgress: true }, () => {
          this.sendOTP(data)
        })
        break
      case stageVerifyOTP:
        this.setState({ submitEnabled: false, inProgress: true }, () => {
          this.verifyOTP(data)
        })
        break

      default:
      // noop
    }
  }

  handleChange = (key, value, data) => {
    data[key] = value

    let submitEnabled = true
    let contactNumberValidated = "default"
    let verifyKeyValidated = "default"

    // verify contactNumber
    switch (key) {
      case "contactNumber":
        {
          const result = validateAll(
            [
              { func: "isLength", opts: { min: 10, max: 10 } },
              { func: "isInt", opts: { min: 1000000000, max: 9999999999, allow_leading_zeroes: false } },
            ],
            value
          )
          if (!result) {
            submitEnabled = false
            contactNumberValidated = "error"
          }
        }
        break

      case "verifyKey":
        {
          const result = validateAll(
            [
              { func: "isLength", opts: { min: 4, max: 8 } },
              { func: "isInt", opts: { min: 0, max: 99999999 } },
            ],
            value
          )
          if (!result) {
            submitEnabled = false
            verifyKeyValidated = "error"
          }
        }
        break

      default:
      // noop
    }
    this.setState({
      data: { ...data },
      submitEnabled: submitEnabled,
      contactNumberValidated: contactNumberValidated,
      verifyKeyValidated: verifyKeyValidated,
    })
  }

  render() {
    const {
      inProgress,
      stage,
      submitEnabled,
      data = {},
      contactNumberValidated,
      verifyKeyValidated,
    } = this.state

    const otpInput =
      stage === stageVerifyOTP ? (
        <OTPInput id="verifyKey" data={data} onChange={this.handleChange} validated={verifyKeyValidated} />
      ) : null

    return (
      <Form isWidthLimited isHorizontal>
        <MobileInput
          id="contactNumber"
          data={data}
          onChange={this.handleChange}
          isDisabled={stage !== stageVerifyContact}
          validated={contactNumberValidated}
        />

        {otpInput}

        <ActionGroup>
          <Button
            variant="primary"
            isDisabled={!submitEnabled}
            onClick={() => this.executeSubmit(stage, data)}
            isLoading={inProgress}
          >
            Proceed
          </Button>
          <Button variant="link">Cancel</Button>
        </ActionGroup>
      </Form>
    )
  }
}

// helper functions
const MobileInput = ({
  id = "",
  data = {},
  validated = "default",
  onChange = () => {},
  isDisabled = false,
}) => {
  return (
    <FormGroup label="Mobile Number" isRequired>
      <InputGroup>
        <InputGroupText>
          <Text>+91</Text>
        </InputGroupText>
        <InputGroupItem isFill>
          <TextInput
            isRequired
            type="text"
            placeholder="Enter your mobile number"
            id={id}
            name={id}
            value={data[id]}
            onChange={(_event, value) => onChange(id, value, data)}
            validated={validated}
            isDisabled={isDisabled}
          />
        </InputGroupItem>
      </InputGroup>
      {validated === "error" ? (
        <FormHelperText>
          <HelperText>
            <HelperTextItem variant="error">Must be a valid 10 digits mobile number</HelperTextItem>
          </HelperText>
        </FormHelperText>
      ) : null}
    </FormGroup>
  )
}

const OTPInput = ({ id = "", data = {}, validated = "default", onChange = () => {}, isDisabled = false }) => {
  return (
    <FormGroup label="OTP" isRequired>
      <InputGroup>
        <InputGroupItem isFill>
          <TextInput
            isRequired
            type="text"
            placeholder="Enter OTP"
            id={id}
            name={id}
            value={data[id]}
            onChange={(_event, value) => onChange(id, value, data)}
            validated={validated}
            isDisabled={isDisabled}
          />
        </InputGroupItem>
      </InputGroup>
      {validated === "error" ? (
        <FormHelperText>
          <HelperText>
            <HelperTextItem variant="error">Must be a valid OTP</HelperTextItem>
          </HelperText>
        </FormHelperText>
      ) : null}
    </FormGroup>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  addAlert: (data) => dispatch(toasterAdd(data)),
  authSuccess: (data) => dispatch(authSuccess(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
