import { Divider, Text, TextContent, TextVariants } from "@patternfly/react-core"
import React from "react"
import { Email } from "../../Components/Footer/Contact"

const faqs = [
  {
    q: "Does this app collect user's data?",
    a: "We do not collect any user data. However we use third party advertisement which may collect data e.g device ip address to provide more relevant ad.",
  },
  {
    q: "Does this app collects personally identifiable information?",
    a: "We do not collect any personally identifiable information.",
  },
  {
    q: "Do we log files?",
    a: "We do not log files. The distribution platform may collect analytics data e.g. crash reports.",
  },
  {
    q: "Do we track user?",
    a: "We do not track user.",
  },
  {
    q: "Do we share any data to third party?",
    a: "We do not collect user data or share it to third party.",
  },
]

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <TextContent>
          <Text component={TextVariants.h1}>Privacy Policy</Text>
          <div>
            Data privacy of our user is our top priority. <br />
            This article outlines privacy policy of <strong>Navani Diagnostic Centre (NDC).</strong>
          </div>
          {faqs.map((item) => getContent(item))}
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          <div>You can write to us at<Email email="navani.lab@gmail.com" hideIcon />for any query regarding privacy policy.</div>
        </TextContent>
      </>
    )
  }
}

export default PrivacyPolicy

const getContent = ({ q = "", a = "" }) => {
  return (
    <>
      <Text component={TextVariants.h2}>{q}</Text>
      <div>{a}</div>
    </>
  )
}
