import { Button, Divider, Flex, FlexItem, Modal, Spinner, Tooltip } from "@patternfly/react-core"
import { DownloadIcon, TableIcon } from "@patternfly/react-icons"
import React from "react"
import { api } from "../../../Service/Api"
import fileDownload from "js-file-download"
import { getValue } from "../../../Util/Util"
import moment from "moment"
import { Base64 } from "js-base64"
import { Document, Page, pdfjs } from "react-pdf"
import { SizeMe } from "react-sizeme"
import { connect } from "react-redux"
import { toasterAdd } from "../../../store/entities/toaster"

import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

// refer: https://github.com/wojtekmaj/react-pdf/issues/782
// used: https://github.com/wojtekmaj/react-pdf/issues/782#issuecomment-1414598468
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

class PDFReport extends React.Component {
  state = {
    downloading: false,
    previewing: false,
    isModelOpen: false,
    reportData: "",
    numberOfPages: 0,
    previewTitle: "",
  }

  componentDidMount() {}

  onPdfPreviewLoadSuccess = ({ numPages }) => {
    this.setState({ numberOfPages: numPages })
  }

  onPreviewClose = () => {
    this.setState({ previewing: false, isModelOpen: false, reportData: "" })
  }

  onPreviewClick = () => {
    this.setState({ previewing: true }, () => {
      const { labBillId, reportName } = this.props
      api.patient
        .getReport({ labBillId: labBillId })
        .then((res) => {
          if (res.status === 205) {
            this.props.addAlert({
              title: "Report not available",
              description: ["Report is not ready, please try again later"],
              type: "warning",
            })
            this.setState({ previewing: false })
          } else {
            let reportTitle = ""
            const reportDetails = getValue(res.data, "ReportDetails", null)
            if (reportDetails && reportDetails[0]) {
              const report = reportDetails[0]
              const patientName = report["Patient Name"]
              const reportDate = moment(report["Report Date"]).format("DD-MMM-YYYY hh:mm A")
              reportTitle = `${patientName} | ${reportDate} | ${reportName}`
            }

            const pdfDataBase64 = res.data.reportsData
            this.setState({
              previewing: false,
              isModelOpen: true,
              reportData: pdfDataBase64,
              reportTitle: reportTitle,
            })
          }
        })
        .catch((_err) => {
          this.setState({ previewing: false })
        })
    })
  }

  onDownloadClick = () => {
    this.setState({ downloading: true }, () => {
      const { labBillId, reportName } = this.props
      api.patient
        .getReport({ labBillId: labBillId })
        .then((res) => {
          if (res.status === 205) {
            this.props.addAlert({
              title: "Report not available",
              description: ["Report is not ready, please try again later"],
              type: "warning",
            })
          } else {
            const pdfDataBase64 = res.data.reportsData
            let downloadFilename = "report.pdf"

            const reportDetails = getValue(res.data, "ReportDetails", null)
            if (reportDetails && reportDetails[0]) {
              const report = reportDetails[0]
              const patientName = report["Patient Name"].replaceAll(" ", "_")
              const reportDate = moment(report["Report Date"]).format("YYYY-MMM-DD_HH_mm")
              downloadFilename = `${patientName}_${labBillId}_${reportDate}_${reportName}.pdf`
                .toLowerCase()
                .replaceAll(" ", "-")
                .replaceAll("/", "")
            }
            const pdfFileContent = Base64.toUint8Array(pdfDataBase64)
            fileDownload(pdfFileContent, downloadFilename)
          }
          this.setState({ downloading: false })
        })
        .catch((_err) => {
          this.setState({ downloading: false })
        })
    })
  }

  render() {
    const { downloading, isModelOpen, reportData, previewing, numberOfPages, reportTitle } = this.state
    const { labBillId } = this.props
    return (
      <>
        <Modal
          key={labBillId}
          isOpen={isModelOpen}
          onClose={this.onPreviewClose}
          aria-label="report-pdf-preview"
          width={"65%"}
          title={reportTitle}
        >
          <SizeMe key="pdf-file-display-div">
            {({ size }) => (
              <Document
                key="pdf-document"
                file={`data:application/pdf;base64,${reportData}`}
                onLoadSuccess={this.onPdfPreviewLoadSuccess}
              >
                {Array.from(new Array(numberOfPages), (_el, index) => (
                  <>
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={size.width ? size.width - 20 : 800}
                    />
                    {index + 1 !== numberOfPages ? (
                      <Divider key={`divider_${index + 1}`} style={{ marginBottom: "10px" }} />
                    ) : null}
                  </>
                ))}
              </Document>
            )}
          </SizeMe>
        </Modal>
        <Flex>
          {
            <FlexItem>
              <Tooltip content="Preview" disabled={downloading}>
                <Button
                  variant="link"
                  icon={previewing ? <Spinner size="md" aria-label="spinner" /> : <TableIcon />}
                  isDisabled={previewing}
                  onClick={this.onPreviewClick}
                />
              </Tooltip>
            </FlexItem>
          }
          <FlexItem>
            <Tooltip content="Download" disabled={downloading}>
              <Button
                variant="link"
                icon={downloading ? <Spinner size="md" aria-label="spinner" /> : <DownloadIcon />}
                isDisabled={downloading}
                onClick={this.onDownloadClick}
              />
            </Tooltip>
          </FlexItem>
        </Flex>
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  addAlert: (data) => dispatch(toasterAdd(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PDFReport)
