import React from "react"
import { api } from "../../../Service/Api"
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table"
import { LastSeen } from "../../../Components/DataDisplay/Time"
import { Label } from "@patternfly/react-core"
import PDFReport from "./PdfReport"
import TableLoading from "../../../Components/Loading/TableLoading"
import { redirect as r, routeMap as rMap } from "../../../Service/Routes"

const columnNames = {
  name: "Name",
  labBillId: "Lab Bill ID",
  reportAge: "Report Age",
  testName: "Test Name",
  totalAmount: "Total Amount",
  concession: "Concession",
  billAmount: "Bill Amount",
  status: "Status",
}

class ListReport extends React.Component {
  state = {
    reports: {},
    reportsHead: {},
    loading: true,
    expandedRowId: undefined,
  }

  componentDidMount() {
    const { labUserId } = this.props.match.params
    api.patient
      .listReports({ labUserId: labUserId })
      .then((res) => {
        const reports = {}
        const reportsHead = {}
        res.data.Result.forEach((report) => {
          const testConcessionAmount = report.testConsc ? report.testConsc : 0
          const _tinyReport = {
            id: report.id,
            patientName: report.billForId.userDetailsId.fullName,
            labBillId: report.billForId.labBillId,
            testName: report.testname,
            testTotalAmount: report.testAmount,
            testAmountConcession: testConcessionAmount,
            testAmount: report.testAmount - testConcessionAmount,
            billTime: report.billForId.billTime,
            isComplete: report.billForId.isComplete === 1 && report.billForId.billLocked === 0,
          }
          if (!reports[_tinyReport.labBillId]) {
            reports[_tinyReport.labBillId] = [_tinyReport]
            reportsHead[_tinyReport.labBillId] = {
              ..._tinyReport,
              billTotalAmount: report.billForId.billTotalAmount + report.billForId.billConcession,
              billConcession: report.billForId.billConcession,
              billAmount: report.billForId.billTotalAmount,
            }
          } else {
            reports[_tinyReport.labBillId].push(_tinyReport)
          }
        })

        this.setState({ reports: reports, reportsHead: reportsHead, loading: false })
      })
      .catch((_err) => {
        if (_err.response.status === 401) {
          const { history } = this.props
          r(history, rMap.patient.login)
          return
        } else {
          this.setState({ reports: {}, reportsHead: {}, loading: false })
        }
      })
  }

  updateExpanded = (previousRowId, rowId) => {
    if (previousRowId === rowId) {
      rowId = undefined
    }
    this.setState({ expandedRowId: rowId })
  }

  render() {
    const { loading, reports, reportsHead, expandedRowId } = this.state
    if (loading) {
      return <TableLoading key="report-loading" />
    }

    const labBillIds = Object.keys(reports)
    labBillIds.sort((a, b) => b - a)

    return (
      <>
        <Table aria-label="main-table" isStriped isExpandable>
          <Thead>
            <Tr>
              <Th />
              <Th>{columnNames.name}</Th>
              <Th>{columnNames.labBillId}</Th>
              <Th>{columnNames.reportAge}</Th>
              <Th>{columnNames.testName}</Th>
              <Th>{columnNames.totalAmount}</Th>
              <Th>{columnNames.concession}</Th>
              <Th>{columnNames.billAmount}</Th>
              <Th>{columnNames.status}</Th>
            </Tr>
          </Thead>
          {labBillIds.map((labBillId, rowIndex) => {
            const reportHead = reportsHead[labBillId]
            const isExpanded = rowIndex === expandedRowId
            return (
              <Tbody key={`main-table-body-${rowIndex}`} isExpanded={isExpanded}>
                <Tr key={reportHead.id}>
                  <Td
                    expand={{
                      rowIndex,
                      isExpanded: isExpanded,
                      onToggle: () => this.updateExpanded(expandedRowId, rowIndex),
                      expandId: `expandable-${reportHead.id}`,
                    }}
                  ></Td>
                  <Td dataLabel={columnNames.name}>{reportHead.patientName}</Td>
                  <Td dataLabel={columnNames.labBillId}>{reportHead.labBillId}</Td>
                  <Td dataLabel={columnNames.reportAge}>
                    <LastSeen date={reportHead.billTime} />
                  </Td>
                  <Td dataLabel={columnNames.testName}>{reportHead.testName}</Td>
                  <Td dataLabel={columnNames.totalAmount}>{reportHead.billTotalAmount}</Td>
                  <Td dataLabel={columnNames.concession}>{reportHead.billConcession}</Td>
                  <Td dataLabel={columnNames.billAmount}>{reportHead.billAmount}</Td>
                  <Td dataLabel={columnNames.status}>
                  <PDFReport labBillId={reportHead.labBillId} reportName={reportHead.testName.trim()} />
                    {// reportHead.isComplete ? (
                     // <PDFReport labBillId={reportHead.labBillId} reportName={reportHead.testName.trim()} />
                     // ) : (
                     // <ReportStatus isCompleted={reportHead.isComplete} />
                     // )
                    }
                  </Td>
                </Tr>
                <Tr isExpanded={isExpanded}>
                  <Td />
                  <Td colSpan={12} noPadding>
                    <ExpandableRowContent>
                      <NestedTable reports={reports[labBillId]} />
                    </ExpandableRowContent>
                  </Td>
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </>
    )
  }
}

// helper functions

const subColumNames = {
  reportAge: "Report Age",
  testName: "Test Name",
  totalAmount: "Total Amount",
  concession: "Concession",
  billAmount: "Bill Amount",
  status: "Status",
}
const NestedTable = ({ reports = [] }) => {
  return (
    <Table aria-label="detailed-table" variant="compact" isNested borders={false} isStriped>
      <Thead>
        <Tr>
          <Th>{subColumNames.reportAge}</Th>
          <Th>{subColumNames.testName}</Th>
          {
            // <Th>{subColumNames.totalAmount}</Th>
            // <Th>{subColumNames.concession}</Th>
            // <Th>{subColumNames.billAmount}</Th>
          }
          <Th>{subColumNames.status}</Th>
        </Tr>
      </Thead>
      <Tbody key={"sub-table"}>
        {reports.map((report, rowIndex) => {
          return (
            <Tr key={`${rowIndex}-${report.id}`}>
              <Td dataLabel={subColumNames.reportAge}>
                <LastSeen date={report.billTime} />
              </Td>
              <Td dataLabel={subColumNames.testName}>{report.testName}</Td>
              {
                // <Td dataLabel={subColumNames.totalAmount}>{report.testTotalAmount}</Td>
                // <Td dataLabel={subColumNames.concession}>{report.testAmountConcession}</Td>
                // <Td dataLabel={subColumNames.billAmount}>{report.testAmount}</Td>
              }
              <Td dataLabel={subColumNames.status}>
                <ReportStatus isCompleted={report.isComplete} />
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

const ReportStatus = ({ isCompleted = false }) => {
  let status = "Payment not complete"
  let labelColor = "gold"
  if (isCompleted) {
    status = "Payment done"
    labelColor = "green"
  }
  return (
    <Label variant="filled" color={labelColor}>
      {status}
    </Label>
  )
}

export default ListReport
